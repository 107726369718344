@import 'styles/resources.scss';

.notes {
  display: flex;
  flex-direction: column;
  width: 340px;
}

.header {
  font-weight: bold;
  margin-bottom: 24px;
  color: $primary-color;
}

.textarea {
  width: 344px;
  height: 188px;
  resize: none;
  border: 1px solid $input-border;
  border-radius: 3px;
  font-size: 100%;
  padding: 8px 16px;
  color: $primary-color;

  &::placeholder {
    color: $input-placeholder;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}