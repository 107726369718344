.newchangesfilters {
  font-size: 12px;
  color: grey;
  display: flex;
}
.header {
  background-color: #f59588;
  margin-left: -16px;
  margin-right: -16px;
  span {color:black}
}
