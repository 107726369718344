@import 'styles/resources.scss';

.tableText {
  display: flex;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

.id {
  width: 8%;
}

.description {
  width: 30%;
}

.changeType {
  width: 11%;
}

.changes {
  width: 23%;
}

.modifiedBy {
  width: 8%;
}

.modifiedDate {
  width: 8%;
}

.notes {
  width: 5%;
}

.canRevert {
  width: 5%;
}
