@import "styles/resources";

.root {
  border-bottom: 1px solid $geyser;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
  margin-right: 10px;
  > * {
    margin: 0 0 10px 16px;
    margin-left: 16px;
  }

  button {
    @include fontSize(14px);
    font-family: $font-family-roboto;
  }
}
