@import 'styles/resources';

$row-height: 28px;

.cell {
  padding: 0;
  vertical-align: top;
}

.spacer {
  height: $row-height;
  min-height: $row-height;
}

.headerRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $row-height;
  min-height: $row-height;

  &:nth-child(odd) {
    background-color: $lighter-gray;
  }

  background: $white;
  color: $slate;
  font-size: 10px;
  padding-right: 16px;
}
