@import 'styles/resources.scss';

.dropDownListSelectContainer {
  border: none;
  box-shadow: 0 5px 8px -5px $gray;
}

.dropDownListSelect {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    list-style: none;
    padding: 8px 12px;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    background-color: $white;

    &:hover {
      background-color: $light-blue;
    }
  }
}
