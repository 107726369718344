@import 'styles/resources';

.beforeText {
  font-weight: bold;
}

.afterText {
  font-weight: bold;
  color: $red;
}
