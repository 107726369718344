@import 'styles/resources.scss';

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: $table-body-font-size;
  margin-top: 27.5px;
  border: 1px solid $lighter-gray;

  &, * {
    box-sizing: border-box;
  }

  thead {
    tr {
      background-color: $lighter-slate;
      td {
        height: 16px;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  thead, tbody {
    tr {
      height: 48px;
      border: 1px solid $lighter-gray;

      td {
        top: -1px;
        letter-spacing: 0.22px;
        line-height: 15px;
        padding-left: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        height: 20px;
        font-size: $table-body-font-size;
      }
    }
  }
}

.downloadCta {
  color: $blue;
  font-size: $table-body-font-size;
  font-weight: 400;
}

.date {
  color: $slate;
}
