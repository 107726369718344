@import 'styles/resources.scss';

.row {
  display: flex;
  height: 48px;
  padding: 16px 48px;

  .series,
  .empty {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: $black;
  }
  .empty {
    padding-left: 20px;
  }
}
