@import 'styles/resources.scss';

.container {
  display: inline-flex;
  padding: 4px 8px 3px 6px;
  border: 1px solid $light-gray;
  align-items: center;
  border-radius: 20px;
}

.clickable {
  cursor: pointer;
}

.iconContainer {
  font-size: 12px !important;
  margin-right: 6px;

  width: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenCircle {
  color: $green;
}

.purpleCircle {
  color: $purple;
}

.orangeCircle {
  color: $buttercup;
}

.statusMessage {
  font-family: $font-family;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: $slate;
  white-space: nowrap;
}

.limitedDataStatusHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 140px;
  white-space: nowrap;
}
