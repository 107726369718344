@import 'styles/resources';

.root {
  position: absolute;
  top: 5px;
  right: 2px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  .flagIcon {
    color: $slate;
    &.active {
      color: $buttercup;
    }
  }
}
