@import '../../../styles/resources.scss';

.iconBtn {
  justify-content: flex-start !important;
  color: $slate !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
}

.dropdownContent {
  width: 210px !important;

  > button {
    justify-content: flex-start !important;
    color: $black !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}
