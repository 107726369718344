@import 'styles/resources';

.removeSelection {
  width: 100%;
  padding: 5px 0;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #89969f;
  span {
    padding-right: 5px;
    color: $gray;
  }
}

.removeSelectionBtn {
  font-size: 12px;
}

.splitRow {
  background-color: $lighter-gray;
}

.splitCellBorderBotttom {
  border-bottom: 1px solid #d8d8d8;
}

.splitCategory {
  @include fontSize(12px);
  font-family: $font-family-roboto;
  padding-left: 10px;
  color: $slate;
}

// used if no descriptions are shown, to make column header is large enough for content
.flagsCellLarger {
  min-width: 150px;
}

.deleteCellSplit {
  background-color: $white;
}

.linkSpec {
  position: relative;
  color: $slate;
  margin: 5px;
  top: 33px;
  display: initial;
  h4 {
    color: rgb(0, 0, 0);
  }
}

.iconBlue {
  color: $blue;
}

.modelPopover {
  position: relative;
  width: 400px;
  min-height: 200px;
  left: 0px;
}
.tableRow {
  display: table-row;
}
.ClDescription {
  color: $gray;
}

.sortInputField {
  width: 40px;
  margin: auto;
  input {
    text-align: center;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.categoryColumn {
  padding-right: 30px;
}

.acceptChangesThumbsUp {
  display: flex;
  justify-content: center;
  align-items: center;
}