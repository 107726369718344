@import 'styles/resources.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  padding: 0 $padding-h;
}

.headerSection {
  display: flex;
}

.title {
  margin: 24px 0 35px;
}

.moduleTitle {
 color: $secondary-color;
 font-weight: normal;
}

.moduleSubtitle {
  color: $primary-color;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
