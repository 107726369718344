@import 'styles/resources';

$row-height: 45px;
$cell-width: 120px;
$spacer-width: 10px;
$border-style: 1px solid $lighter-gray;

.row {
  height: $row-height;
  min-height: $row-height;

  td {
    padding: 0;
  }
}

.spacerCell {
  width: $spacer-width;
  min-width: $spacer-width;
  background: $white;
}

.cell {
  border: $border-style;
  width: $cell-width;
  min-width: $cell-width;

  .cellContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    height: 43px;
    text-align: center;
  }
}
