@import 'styles/resources';

.importIcon {
  margin-left: 8px;
  color: $green;
}

.sticky {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;

}