#formControlBase {
  appearance: none;
  padding: calc(0.375em - 1px) 0.75em;
  height: $form-control-height;
  line-height: 1.5;
  text-decoration: none;
  font-size: 12px; //1rem
}

@mixin formControl() {
  @extend #formControlBase;
  display: inline-flex;
  align-items: center;
  border: 1px solid $input-border;
  border-radius: 3px;

  &:disabled {
    cursor: default;
  }

  &::placeholder {
    color: $input-placeholder;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &.error {
    border-color: $input-error-border;
    background-color: $input-error-background;
    color: $input-error-color;

    &::placeholder {
      color: $input-error-placeholder-color;
    }
  }
}
