@import 'styles/resources.scss';

$table-font-size: 13px;

.dropdown {
  width: 100%;
  position: static;
  li {
    list-style: none;
    padding: 10px 8px;
  }
  li:hover {
    background-color: $light-blue;
  }
}

.dropdownButton {
  width: 100%;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 3px;
  min-height: 27px;
}

.dropdownContent {
  z-index: 99999;
}

.seriesSettingRow {
  td {
    padding: 5px;
    label {
      font-weight: bold;
      width: 100%;
      display: block;
      margin-bottom: 5px;
    }
  }
}

.seriesSettingsHeaderRow {
  td {
    text-align: center;
  }
}

.seriesSettingRowCollapsed {
  td:last-child {
    text-align: right;
    padding: 0 18px 0 0;
  }

  td {
    padding: 0 5px 0 18px;
  }
}

.isPublishableChbox {
  margin: 0 auto;
  width: 18px;
  input:checked + label {
    &:after,
    &:before {
      border-color: $red !important;
    }
  }
}

.isConvertibleChbox {
  margin: 0 auto;
  width: 18px;

  input:checked + label {
    &:after,
    &:before {
      border-color: $blue !important;
    }
  }

  &ReadOnly {
    display: flex;
    justify-content: center;
    text-wrap: nowrap;
    padding: 0;

    input:checked + label {
      &:before {
        border-color: transparent !important;
        background-color: transparent !important;

        left: -2px;
        top: 1px;
        width: 24px;
        height: 24px;
      }
    }

    input:checked + label {
      &:after {
        border-color: $blue !important;
        width: 7px;
        height: 14px;

        border-bottom: 5px solid;
        border-right: 5px solid;
      }
    }
  }
}

.iconTextButtonSubSeries {
  display: block;
  margin-left: auto;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;

  &:focus {
    border-color: $input-error-border;
    background-color: $input-error-background;
  }
}

.seriesName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  button {
    position: relative !important;
  }
}

.seriesNameEdit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 22px;

  button {
    position: relative !important;
  }
}

.seriesSettingRowTdPR {
  td:not(:nth-child(2)) {
    padding-top: 33.5px;
  }
}

.ctaCol {
  min-width: 88px;
}

.isPublishableChbox {
  input:checked + label {
    &:after,
    &:before {
      border-color: $red !important;
    }
  }
}

.seatingCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  > div {
    margin-top: 3px;
  }
}

.mileageCol {
  @extend .seatingCol;
  align-items: center;
}

.mpgCol {
  @extend .seatingCol;
  align-items: flex-start;
}

.seatingHeader {
  text-align: right;
}

.mileageHeader {
  text-align: center;
}

.convertibleHeader {
  width: 78px;
  text-wrap: nowrap;
}

.actionsHeader {
  width: 168px;

  button {
    justify-content: flex-end;
  }
}

.codeRedHeader {
  width: 61px;
  text-wrap: nowrap;
}
