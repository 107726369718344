@import 'styles/resources';

$totalRowHeight: 40px;

@mixin total {
  height: $totalRowHeight;
  max-height: $totalRowHeight;
  font-size: 11px;

  td {
    padding-left: 18px;
  }
}

.packageTotal {
  @include total();
  background: $light-yellow;
}

.packageTotalRequirements {
  @include total();
  background: $light-teal;
}

.deleted {
  background-color: $light-gray;
}

.packageTotalInput {
  div[contenteditable='true'] {
    width: 120px;
  }
}
