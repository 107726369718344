.twoTableWrapper {
  display: flex;

  .leftTable {
    width: auto !important;
    max-width: 80% !important;

    .applyAccept {
      min-width: 100px !important;
      max-width: 100px !important;
    }

    .category {
      min-width: 200px !important;
      max-width: 200px !important;
    }

    .name {
      min-width: 300px !important;
      max-width: 300px !important;
    }

    .description {
      min-width: 350px !important;
      max-width: 350px !important;
    }

    .code {
      min-width: 100px !important;
      max-width: 100px !important;
    }

    .msrp {
      min-width: 100px !important;
      max-width: 100px !important;
    }

    .addedDeleted {
      min-width: 120px !important;
      max-width: 120px !important;
    }

    .flags {
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }

  .modelApplicability {
    width: 163px !important;
  }

  table {
    thead {
      tr {
        height: unset;
      }
    }
  }
}
