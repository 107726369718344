@import 'styles/resources.scss';

.root {
  display: flex;
  flex-direction: column;
  max-width: 380px;
}

.header {
  font-weight: bold;
  margin-bottom: 24px;
  color: $primary-color;
}

.textInput {
  width: 344px;
  resize: none;
  border: 1px solid $input-border;
  border-radius: 3px;
  font-size: 100%;
  padding: 8px 16px;
  color: $primary-color;

  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::placeholder {
    color: $input-placeholder;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
