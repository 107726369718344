@import '../../../styles/resources.scss';

.error {
  @include formControl();
}

.dropdownList {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 200px;
  overflow-y: scroll;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: 240px;
    padding: 16px 16px;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
    }
  }
}

.placeholder {
  color: $input-placeholder;
}
