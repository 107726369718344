@import 'styles/resources.scss';

.applicabilityText {
  display: flex;
  flex-direction: column;
  width: 340px;
}

.header {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 24px;
  color: $primary-color;
}

.grade {
  font-size: 16px;
  margin-bottom: 24px;
  color: $primary-color;
}

.textarea {
  width: 344px;
  height: 50px;
  resize: none;
  border: 1px solid $input-border;
  border-radius: 3px;
  font-size: 100%;
  padding: 8px 16px;
  color: $primary-color;

  &::-webkit-input-placeholder {
    color: $input-placeholder;
  }

  &::-moz-placeholder {
    color: $input-placeholder;
  }

  &:-ms-input-placeholder {
    color: $input-placeholder;
  }

  &::-ms-input-placeholder {
    color: $input-placeholder;
  }

  &::placeholder {
    color: $input-placeholder;
  }
}

.langTitle {
  color: $black;
  padding-bottom: 5px;
  font-weight: 500;
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.syncValueChanged {
  background-color: $input-error-background;
}
