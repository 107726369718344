.tableContainer {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.headerCellText {
  font-size: 12px;
  font-weight: 600;
}

.tableCellText {
  padding: 0 16px;
}

.textBlock {
  display: flex;
  align-items: center;
  height: 84px;
  overflow-y: auto;
  overflow-x: hidden;
}

.textBlockSize {
  width: 130px;
  word-break: break-all;
}

.richTextSize {
  width: 160px;
}

.richTextSection {
  > section {
    width: 160px;
  }
}

.fieldColumn {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}
