@import 'styles/resources';

.shortCode {
  width: 10%;
}

.description {
  width: 30%;
}

.changeType {
  width: 11%;
}

.changes {
  width: 23%;
}

.modifiedBy {
  width: 8%;
}

.modifiedDate {
  width: 8%;
}

.notes {
  width: 5%;
}

.canRevert {
  width: 5%;
}
