@import 'styles/resources.scss';

.grayRow {
  height: 20px;
  background-color: $gray;
}

.table {
  font-size: 14px;

  td {
    border: 1px solid $mystic;
  }
}

.nameCell {
  background-color: $white;
  padding-left: 16px;
  width: 11%;
}

.acceptCell {
  width: 90px;
}

.categoryCell {
  padding-left: 16px;
  width: 25%;
}

.descriptionCell {
  .grade {
    display: flex;
    align-items: center;
    border: 1px solid $mystic;
    border-top: none;
    border-left: none;
    border-right: none;

    label {
      margin-right: 16px;
      font-weight: 500;
    }

    .changes {
      display: flex;
      flex-direction: column;
    }
  }
}

.applicabilityCell {
  padding-left: 16px;
  width: 20%;

  ul {
    padding-left: 0;
  }
}

.addedCell {
  width: 150px;
}

.noMargin {
  margin: 0;
}
