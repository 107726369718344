// Styles coming from React Block UI

.blockUi {
  position: relative;
  min-height: 3em;
}

.blockUiContainer {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}

.blockUiOverlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  background-color: white;
}

.blockUiMessageContainer {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10001;
}

.blockUiMessage {
  color: #333;
  background: none;
  z-index: 1011;
}