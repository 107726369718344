@import 'styles/resources.scss';

.accordionItem {
  overflow: hidden;
}

.shadow {
  box-shadow: 0 5px 8px -5px $gray;
}

.actionButtons {
  display: flex;
  align-items: center;
}

.actionItems {
  color: $slate;
  margin-left: auto;
}

.edit,
.trash {
  float: right;
  margin-right: 15px;
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}

.disabledTrash {
  @extend .trash;
  color: $light-gray;
  pointer-events: none;
  &:hover {
    color: $light-gray;
  }
}

.inputRow {
  display: flex;
  align-items: center;
}

.inputBox1 {
  width: 150px !important;
  margin-right: 40px;
  height: 40px !important;
  border-radius: 3px !important;
  border: 1px solid $light-gray !important;
  color: $black;
}

.inputBox2 {
  @extend .inputBox1;
  width: 120px !important;
}

.inputBox3 {
  @extend .inputBox2;
  margin-right: 0;
}

.cta {
  display: flex;
  margin-left: auto;
}

.header {
  display: flex;

  > span:not(:last-child) {
    margin-right: 40px;
  }

  > .title1 {
    width: 150px;
  }

  > .title2 {
    width: 120px;
  }

  > .title3 {
    width: 200px;
  }
}

.tableDropdown1 {
  width: 150px;
  margin-right: 40px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid $light-gray;
  color: $black;
  text-align: left;

  &:disabled {
    border: none;
    background-image: none;
    cursor: default;
  }
}

.tableDropdown2 {
  @extend .tableDropdown1;
  width: 120px;
}

.tableDropdown3 {
  @extend .tableDropdown1;
  margin-right: 20px;
}

.accordionContainer {
  height: 72px !important;

  > label {
    display: flex;
    align-items: center;

    > svg {
      color: $black !important;
    }
    > span {
      padding-left: 16px !important;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
    }
  }
}

.accordionContainerEdit {
  @extend .accordionContainer;

  > label {
    > svg {
      display: none;
    }

    > span {
      padding-left: 28px !important;
    }
  }
}

.rowOnEdit {
  background: $lighter-slate;
}

.editButton {
  height: 32px !important;
  width: 79px;
}

.dropdownContainer {
  position: static !important;

  > div {
    position: static;

    > div {
      top: auto;
    }
  }
}

.checkBox {
  margin-bottom: 5px;
}
