@import 'styles/resources.scss';

.dropdownContainer {
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.123585);
  width: 290px;
  padding: 6px 16px 0 16px;

  > .checkBoxList:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }
}

.checkBoxList {
  padding: 20px 0 20px 12px;

  > .checkBox:not(:last-child) {
    margin-bottom: 8px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 38px 22px 0;
}

.cancelButton {
  font-weight: 400;
  font-size: 14px;
  width: 92px;
  height: 32px;
}

.saveButton {
  @extend .cancelButton;
  margin-left: 0 !important;
}
