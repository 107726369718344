@import 'styles/resources.scss';

.body {
  padding: 15px;
}

.accordianItem {
  margin-top: 15px;
}

.CTAHeader {
  display: flex;
  justify-content: space-between;
  width: 90%;
  border-bottom: 1px solid $light-gray;
}

.publishButton {
  margin-bottom: 20px;
}
