@import 'styles/resources.scss';
.roboto {
  font-family: 'Roboto', sans-serif;
  input {
    border-color: #cdd1d4;
    background: #fff;
    border-style: solid;
    border: solid 1px #cdd1d4;
    border-radius: 4px;
  }
}
.gradeCheckbox {
  margin-left: -25px;
  display: inline-block;
}
.availabilityHeader {
  position: absolute;
  right: 43px;
}

.deleteButton {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 2px;
  margin-right: 4px;
  margin-left: -17px;
  color: #177de3;
}
.defaultApplicability {
  display: inline-flex;
  right: 7px;
  top: 25px;
  position: absolute;
  &.inlineRadios {
    top: 0;
  }
}
.gutterRight {
  margin-right: 20px;
}

.headers {
  display: flex;
  justify-content: flex-start;
  margin: 14px 6px 7px;
  div {
    padding-right: 10px;
  }
}

.subText {
  font-size: 10px;
  color: $slate;
}
.crowdUp {
  margin-top: -4px;
  margin-bottom: 5px;
}
.applicabilityLabels {
  position: absolute;
  display: flex;
  right: 17px;
  top: 5px;
}

.s {
  padding-right: 17px;
}
.applicabilityText {
  font-size: 12px;
}
.applicabilityTextPlus {
  color: blue;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  left: 1px;
  top: 1.3px;
}
.clickable {
  cursor: pointer;
}
.spaceAround {
  justify-content: space-around;
}
.gradeRow {
  line-height: 1.2rem;
  width: 328px;
  position: relative;
  padding-left: 24px;
}

.checkbox.checkbox {
  line-height: normal;
  ::before {
    top: 0;
  }
  label {
    line-height: 1.2rem;
  }
}
.applicabilityTextSection {
  margin-bottom: 5px;
  input {
    max-width: 132px;
    height: 24px;
    margin-top: -3px;
    padding-left: 5px;
  }
}
.tenRight {
  margin-right: 10px;
}

.gutters {
  padding: 10px 0;
}

.flexy {
  display: flex;
}

.arrow {
  padding: 0 4px;
  margin-left: 5px;
  cursor: pointer;
  &::after {
    content: '';
    position: relative;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid #177de3;
    border-right: 2px solid #177de3;
  }
  &.up::after {
    transform: rotate(226deg);
    bottom: 0px;
  }
  &.down::after {
    transform: rotate(135deg);
    bottom: 3px;
  }
}

.error input[type='radio'] {
  border: solid 2px $red;
}
