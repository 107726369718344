@import '../../../styles/resources.scss';

.input {
  @include formControl();
  box-sizing: border-box;
}

.smallInput {
  width: 45px;
  text-align: center;
  height: 50px;
  margin: 10px;

}

.smallInputHeader {
  text-align: center;
  border-left: 1px solid $table-border;
  border-right: 1px solid $table-border;
}