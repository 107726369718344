@import 'styles/resources.scss';

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 37px;

  &, * {
    box-sizing: border-box;
  }

  thead {
    tr {
      color: $secondary-color;

      td {
        padding: 0 16px;
        vertical-align: middle;
        font-size: $table-body-font-size;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      height: 48px;
      &:hover{
        box-shadow: 0 5px 8px -5px $gray !important;
        transform: scale(1);
        cursor: pointer;
      }
    }
  } 
}
