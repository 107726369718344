@import 'styles/resources';

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 200px;

  &.short {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.postSpinner {
  position: fixed;
  top: 0;
  right: 0;
}

.postRing {
  div {
    border: 8px solid $blue !important;
    border-color: $blue transparent transparent transparent !important;
  }
}

.ldsRing {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $light-gray;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $light-gray transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
