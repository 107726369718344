@import 'styles/resources.scss';

.container {
  display: inline-flex;
  padding: 4px 8px 3px 6px;
  align-items: center;
  border: 1px solid transparent;
}

.cta {
  cursor: pointer;
}

.iconContainer {
  font-size: 12px !important;
  margin-right: 6px;

  width: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: $gray;
}

.hasKeyFeatures {
  color: $buttercup;
}

.hasChanges {
  border: 1px solid $red;
  background: $light-red;
}

.statusMessage {
  font-family: $font-family;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: $slate;
  white-space: nowrap;
}

.keyFeatureList {
  padding-left: 24px;
}

.keyFeatureListItemContainer {
  margin: 0 0 10px 0;

  .keyFeatureListItem {
    display: flex;
    align-items: center;
    position: relative;

    .groupDropdown {
      flex: 1 1 auto;

      &Button {
        width: 100%;
        height: 52px;
        text-align: left;
        border: 1px solid $input-border;
        font-family: $font-family-roboto;
      }

      > div > div {
        width: 100%;
        max-height: 230px;
        overflow: auto;

        ul {
          li {
            width: auto;
          }
        }
      }
    }
  }

  .readOnly {
    display: block;
    font-size: 16px;
    padding: 10px;

    &DefaultLanguage {
      color: $black;
    }

    &EN {
      color: $slate;
      font-style: italic;
    }
  }
}

.zebra:nth-child(odd) {
  background-color: $lighter-slate;
}

.popoverElementClass {
  left: 60px !important;
}

.dialog {
  display: inline-block;
  position: relative;
  z-index: 1040;
  max-height: 100%;

  width: auto;
  min-width: 475px;
}

.header {
  margin-bottom: 8px;
}

.headerContent {
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
}

.headerText {
  color: #152935;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.22px;
  line-height: 21px;
}

.footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.body {
  margin: 8px 0;
}
