.pendingReviewLink {
  a {
    color: blue;
    font-weight: bold;
    font-size: 12px;
  }
}

.pendingReviewContainer {
  width: 240px;
  padding: 7px;
  font-size: 12px;
}
