@import 'styles/resources';

.specLinkDescription {
  height: 120px;
  width: 350px;
}

.stickyNav td {
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 2;
}

.noResize {
  resize: none;
}

.specTable {
  tbody.specTbody tr td {
    padding-right: 50px;
    font-size: 12px;
  }
}

.tableTextArea {
  border: 1px solid $table-input-border;
  border-radius: 3px;
  background-color: transparent;
  color: $primary-color;
  resize: none;
  padding: 8px;
  font-size: $table-body-font-size;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
}
