@import 'styles/resources.scss';

$table-font-size: 13px;

.colorFamilyRow {
  td:last-child {
    text-align: right;
    padding: 0 18px 0 0;
  }

  td {
    padding: 0 5px 0 18px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  font-size: $table-font-size;

  & > div {
    flex: 0 0 157px;
    padding: 8px;
    margin-top: 15px;
  }
}

.dropdown {
  background-color: $white;
  width: 70%;
  border: 1px solid $light-gray;
  border-radius: 3px;
  min-height: 35px;
}

.input {
  min-height: 35px;
  width: 170px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 25px;
}

.deleteIcon {
  color: $gray;
  font-size: 15px;
  padding-top: 7.5px;
}

.editIcon {
  color: $blue;
  padding: 0 15px;
  font-size: 15px;
  padding-top: 7.5px;
}
