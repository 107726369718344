$row-height: 45px;
$input-height: 28px;

@mixin input() {
  height: $input-height;
  background-color: transparent;
  font-size: 12px;
}

.row {
  height: $row-height;
  min-height: $row-height;

  td {
    padding: 5px 8px;
  }
}

.codeInput {
  @include input();
  width: 50px;
  text-transform: uppercase;
}

.descriptionInput {
  @include input();
  width: 150px;
  font-weight: bold;
}

.colorDescriptionBoxLexus {
  & div[role='combobox'] {
    width: 200px;
  }
}
