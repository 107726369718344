@import 'styles/resources.scss';

$popover-border: $slate;

.popoverElementContainer {
  position: absolute;
  top: 100%;
  z-index: 5;
}

.popoverContainer {
  position: absolute;
  display: inline-block;
}

.popover {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.applicabilityText {
  display: inline-block;
  width: 160px;
  text-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoverElement {
  position: relative;
  top: 8px;
  left: -33px;
  border: 1px solid $popover-border;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
  padding: 16px 32px 24px;

  // arrow
  &::before {
    content: '';
    position: absolute;
    top: -6px;
    border-left: 1px solid $popover-border;
    border-top: 1px solid $popover-border;
    width: 10px;
    height: 10px;
    background-color: $white;
    transform: rotate(45deg);
  }
}

.align-right {
  right: 0;

  // arrow
  &::before {
    right: 24px;
  }
}

.align-left {
  left: -285px;

  // arrow
  &::before {
    left: 320px;
  }
}

.applicabilityText {
  text-align: center;
}
