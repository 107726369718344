@import 'styles/resources';

.headerNotificationMenu {
  display: flex;
  justify-content: space-evenly;

  > a {
    padding: 16px;
    white-space: nowrap;
    color: $black;

    &:visited, &:active {
      color: $black;
    }

    &:hover {
      color: $tertiary-color;
    }

    &.active {
      color: $tertiary-color;
      font-weight: bold;
    }
  }
}