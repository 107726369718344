.bodyText {
  font-weight: 400;
}

.container {
  width: 100%;
  box-sizing: border-box;
}

.modalHeader {
  box-sizing: border-box;
  border: 1px solid #cdd1d4;
  border-radius: 4px 4px 0 0;
  background-color: #f4f7fb;
  width: 20vw;
  font-size: 17px;
  height: 44px;
  padding: 10px 0px;
  text-align: center;
}

.selectContext {
  padding-bottom: 5px;
}
