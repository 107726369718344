@import 'styles/resources';

.changeRemoved {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: $red;
  text-decoration-color: $red !important;
  color: $red;
  line-height: normal;

  font-size: 14px;
  label::before {
    background-color: #e0182d47;
  }
  label::after {
    border-color: #e0182d00;
  }
}

.changeAdded {
  color: $green;
  font-weight: 'bold';
  line-height: normal;
  font-size: 14px;
  label::before {
    background-color: #43b1318c;
  }
  label::after {
    border-color: #43b13100;
  }
}
