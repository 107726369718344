@import 'styles/resources';

$border: 1px solid $mystic;

.tr {
  height: $table-row-height;
  min-height: auto;
  border: $border;

  &.zebra {
    &:nth-child(even) {
      background-color: $table-row-background-even;
    }

    &:nth-child(odd) {
      background-color: $table-row-background-odd;
    }
  }

  &.hoverShadow {
    &:hover {
      transform: scale(1, 1);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.rowShadow {
  box-shadow: 7px 0px 7px -7px rgba(0, 0, 0, 0.12);
}
