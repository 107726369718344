@import 'styles/resources.scss';

.vehicleModalBtn {
  cursor: pointer;

  button {
    padding-left: 0;
    padding-right: 0;
  }
  
  &.disabled {
    cursor: default;
  }
}

.modelYear {
  font-size: $table-body-font-size-small;
}