@import 'styles/resources.scss';

.headerContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-left: 20px;

  > span {
    font-weight: 700;
    font-size: $table-body-font-size;
  }

  > span:not(:last-child) {
    margin-right: 40px;
  }

  > .title1 {
    width: 150px;
  }

  > .title2 {
    width: 120px;
  }

  > .title3 {
    width: 200px;
  }

  > .caret {
    color: $black;
    margin-right: 16px;
  }
}
