@import 'styles/resources.scss';

.reportTypeContainer {
  padding: 25px;
}

.reportTypeLabel {
  height: 24px;
  width: 140px;
  font-size: 12px;
  letter-spacing: 0.77px;
  line-height: 21px;
  display: flex;
  margin-top: 5px;
}

.reportTypeBtn,
.seriesBtn,
.modelBtn,
.versionBtn {
  button,
  li {
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 16px;
    padding-left: 15px;
    outline: none;
  }
  li {
    display: flex;
    align-items: center;
    height: 35px;
    &:hover {
      background-color: $white;
      color: $blue;
    }
  }
}

.reportTypeBtn {
  button,
  li {
    height: 32px;
    width: 233px;
  }
}

.seriesContainer,
.versionContainer {
  margin-top: 15px;
  min-height: 55px;
}

.seriesBtn,
.modelBtn,
.versionBtn {
  button,
  li {
    height: 32px;
    width: 190px;
  }
}

.seriesBtn {
  float: left;
  margin-right: 7px;
}

.modelBtn {
  margin-left: 140px;
}

.generateCtaContainer {
  box-sizing: border-box;
  height: 100px;
  border-bottom: 1px solid $geyser;
}

.generateReportCta {
  margin-top: 45px;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 16px;
  width: 151px;
}
