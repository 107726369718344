.stickyHeaderSection {
  position: sticky;
  top: 0;
  z-index: 1;
}

.stickyHeaderSectionWithFilters {
  position: sticky;
  top: 53px;
  z-index: 1;
}
