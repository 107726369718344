.calendarContainer {
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.123585);
}

.calendar {
  border: none !important;
  margin: 24px 53px 30px 54px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3px 24px 0;
}

.cancelButton {
  font-weight: 400;
  font-size: 14px;
  height: 32px;
}

.saveButton {
  @extend .saveButton;
  width: 152px;
}
