@import 'styles/resources.scss';

.value {
  flex: 1 1 auto;
  padding: 16px 2px 16px 16px;
  cursor: pointer;
}

.editBtn {
  padding: 16px 8px 16px 2px;  
}

.inputGroup {
  display: flex;
  padding: 16px 8px;

  .input {
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid $input-border;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
  }

  .inputBtn {
    border: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $light-blue;
    border-color: $blue;
    color: $blue;
    cursor: pointer;
  }
}

.selected {
  background-color: $light-blue;
  color: $blue;
  font-weight: bold;
}