@import 'styles/resources';

.chboxLabel {
  font-family: $font-family-roboto;
  color: $slate;
  padding-left: 3px;

  &.small {
    @include fontSize(10px);
  }
}