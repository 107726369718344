@import 'styles/resources';

$margin: 16px;
$inputWidth: 200px;
$idInputWidth: 80px;
$textAreaWidth: $margin + $inputWidth + $idInputWidth;

.dropdownRow {
  display: flex;
  margin-bottom: $margin;

  > div > button {
    width: $inputWidth;
    margin-right: $margin;
    justify-content: flex-start;
  }

  > input {
    width: $idInputWidth;
  }
}

.inputRow {
  margin-bottom: $margin;
  > input {
    width: $inputWidth;
  }
}

.textAreaRow {
  margin-bottom: $margin;
  > textarea {
    width: $textAreaWidth;
    height: 80px;
  }
}

.currencyInput {
  height: 27px;
}

.currencyInput input {
  width: 100%;
  padding-left: 12px;
}

.currencyInput:after {
  position: relative;
  content: '$';
  left: 3px;
  bottom: 23px;
  font-size: 15px;
}

.treeMenu {
  padding-left: 23px;
  margin-bottom: 16px;
  display: inline-block;
  width: auto;

  label {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-bottom: 2px;
    &::before {
      top: 0;
    }
  }
}

.menuItems {
  height: 20px;
  display: table-caption;
  margin-top: 8px;
  margin-left: 10px;
}

.down {
  &::after {
    content: '';
    position: relative;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    transform: rotate(135deg);
    float: left;
    bottom: 18px;
    left: 134px;
    cursor: pointer;
  }
}

.up {
  &::after {
    transform: rotate(314deg);
    bottom: 10px;
    cursor: pointer;
  }
}
