.flex {
  display: flex;
}

.multiLang {
  height: 112px;
}

.singleLang {
  height: 88px;
}
