@import 'styles/resources';

.changeRemoved {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: $red;
  text-decoration-color: $red !important;
  color: $red;
  line-height: normal;

  font-size: 14px;
  label::before {
    background-color: #e0182d47;
  }
  label::after {
    border-color: #e0182d00;
  }

  margin-bottom: 5px;
}

.changeAdded {
  color: $green;
  font-weight: 'bold';
  line-height: normal;
  font-size: 14px;
  label::before {
    background-color: #43b1318c;
  }
  label::after {
    border-color: #43b13100;
  }
}

.extraCost {
  height: 15px;
  width: 25px;
  font-size: 8px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &-added {
    color: $apple;
    border: 1px solid $apple;
  }
  &-removed {
    color: $red;
    border: 1px solid $red;
  }
}

.changeFrom {
  font-weight: bold;
}

.changeTo {
  font-weight: bold;
  color: $red;
}

.changeModelTitle {
  flex: 1 1 auto;
  padding-top: 3.5px;
}

.applicabilityChanges {
  flex: 1 1 auto;
  padding-top: 3px;
}

.inlineChanges {
  display: flex;
  flex-wrap: wrap;

  .changeRemoved {
    margin-right: 10px;
  }
}
.gradeApplicability {
  .header {
    display: flex;
    div {
      padding-left: 30px;
    }
  }
  .flex {
    display: flex;
  }
  .padding {
    padding-left: 30px;
  }
  .texttop {
    vertical-align: text-top;
  }
  .textCenter {
    text-align: center;
  }
  .column25 {
    width: 25%;
  }
  .column50 {
    width: 50%;
  }
  .full {
    width: 100%;
  }
  .applicabilityText {
    width: 135px;
    font-size: 12px;
    max-width: 132px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sheader {
    width: 17px;
  }
  .headerRow {
    > td {
      padding-bottom: 5px;
    }
  }
}

.red {
  background: red;
}

.defaultFontSize {
  font-size: 14px;
}

.zone {
  margin: 0 0 0 4px;
}

.noWrap {
  white-space: nowrap !important;
}
