@import 'styles/resources';

.flexColumn {
  display: flex;
  flex-direction: column;
}

.categoryColumn {
  padding-right: 25px;
}

.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;
}

.short {
  min-width: 180px !important;
  width: 180px;
  padding: 0;
}

.acceptChangesThumbsUp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flagsCellLarger {
  width: 150px;
}

.tableRow {
  display: table-row;
}

.tdprButton {
  position: unset;
}

.alignCategorySubCategory {
  padding: 5px 0 5px 0;
}
