@import "styles/resources";

.root {
  margin: 24px 0px 15px;
  @include fontSize(10px);
  font-family: $font-family-roboto;

  .back {
    text-decoration: none;
    color: $navy;
    @include fontSize(10px);
    font-weight: 500;
    letter-spacing: 1px;

    .leftArrow {
      position: relative;
      top: 2px;
      height: initial;
      width: 13px;
    }
  }

  .model {
    position: relative;
    top: 1px;
    @include fontSize(14px);
    color: $blue;
    display: inline-block;

    .series {
      font-weight: bold;
    }
  }

  .edit {
    position: relative;
    top: 1px;
    @include fontSize(10px);
    color: $slate;
    padding-left: 8px;
    cursor: pointer;
  }

  .line {
    box-sizing: border-box;
    height: 15px;
    width: 1px;
    border: 1px solid #dfe3e6;
    margin: 0 7px;
  }
}
