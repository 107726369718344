@import 'styles/resources.scss';

.vehicleFilter {
  width: 900px;
  padding: 24px 0 24px 24px;
}

.allVehicles {
  font-weight: bold;
}

.checkbox {
  margin-bottom: 16px;
}

.vehicles {
  display: flex;
}

.vehiclesColumn {
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
}

.vehicle {
  padding-left: 32px;
}

.body {
  background-color: $white;
}

.tabs {
  ul {
    list-style: none;
    display: flex;
    padding: 24px;
    margin: 0;

    li {
      border-bottom: 1px solid $lighter-gray;
      cursor: pointer;
      width: 55px;
      padding: 0 8px 8px 8px;
      transition: border-color $transition-timing, font-color $transition-timing;
      text-align: center;
      font-size: 14px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.active {
        color: $tertiary-color;
        border-bottom: 2px solid $tertiary-color;
        font-weight: bold;
      }
    }
  }
}