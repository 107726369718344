@import 'styles/resources';

$border: 1px solid $mystic;

.td {
  text-align: center;
  @include fontSize(14px);
  font-family: $font-family-roboto;
  color: $shuttle-grey;
  resize: none;
  height: 100%;
  background-color: transparent;
  width: 160px !important;
  border-right: $border;
  display: flex;
  flex-direction: column;
}

.redText {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: $red;
  text-decoration-color: $red !important;
  color: $red;
  line-height: normal;

  font-size: 14px;
  label::before {
    background-color: #e0182d47;
  }
  label::after {
    border-color: #e0182d00;
  }
}

.greenText {
  color: $green;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  label::before {
    background-color: #43b1318c;
  }
  label::after {
    border-color: #43b13100;
  }
}

.autoMargin {
  margin: auto;
}
