@import 'styles/resources';

.colorDescriptionBox {
  & div[role='combobox'] {
    width: 200px;
    height: 28px;
  }
}

.codeInput {
  width: 70px;
  height: 43px;
  border-radius: 4px;
  font-size: $table-body-font-size-small;
  color: $slate;
}

.exteriorInput {
  width: 140px;
  height: 43px;
  border-radius: 4px;
  font-size: $table-body-font-size-small;
}

.extraCostCta {
  height: 15px;
  width: 21px;
  font-size: 7px;
}

.codeCellStyles {
  text-transform: uppercase;
  padding-top: 37px;
}

.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;
}

.acceptChangesThumbsUp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flagsCellLarger {
  min-width: 150px !important;
}
