@import 'styles/resources';

.container {
  white-space: nowrap;
}

.status {
  color: $slate;
  font-weight: bold;
}

.date {
  color: $slate;
}

#indicator {
  position: relative;
  top: -1px;
  font-size: 8px;
  margin-right: 8px;
}

.draft {
  @extend #indicator;
  color: $buttercup;
}

.submitted {
  @extend #indicator;
  color: $purple;
}

.approved {
  @extend #indicator;
  color: $green;
}

.published {
  @extend #indicator;
  color: $blue;
}
