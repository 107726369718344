@import 'styles/resources';

.container {
  overflow: auto;
  height: 50px;
}

.autoHeight {
  height: auto;
}

.changesContainer {
  max-width: 260px;
  word-break: break-all;

  .beforeEditor {
    text-decoration: line-through;
    text-decoration-color: $red;
  }

  > p:first-child {
    margin-top: 0;
  }
}
