@import "styles/resources";
$border: 1px solid $mystic;

.root {
  min-width: 36px;
  text-align: center;
  border-right: $border;
}

.button {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.trashIcon {
  color: $blue;
  width: 15px;
}
