@import "styles/resources";

.tabs {
  .tab {
    font-family: $font-family-roboto;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 16px;
    color: $mine-shaft;
    margin: 0 21px;
    background-color: transparent;
    border: 0;
    padding: 8px 0;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      color: $blue;
      font-weight: bold;
      border-bottom: 2px solid $blue;
    }
    &:disabled{
      color: $slate
    }
  }
}
