@import 'styles/resources.scss';

.body {
  padding: 20px 22px 0 0;
}

.tabContainer {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 20px;
}

.publishHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
}

.publishButton {
  margin-bottom: 8px;
}

.accordianItem {
  margin-top: 15px;
}

.accordianSection {
  width: 100% !important;
}
