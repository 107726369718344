@import 'styles/resources';

.columnHeaderWidth {
  width: 150px;
}

.colorFamily {
  width: 161px;
}

.code {
  width: 115px;
}

.name {
  width: 296px;
}
