@import 'styles/resources';

.dragIcon {
  color: $slate;
  font-size: 18px;
  cursor: pointer;
  margin-left: 5px;

  &.open {
    color: $tertiary-color;
  }
}

.listItem {
  width: 150px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: $lighter-slate !important;
  }

  &:nth-child(even) {
    background-color: $white;
  }
  &:nth-child(odd) {
    background-color: $lighter-slate;
  }
}
