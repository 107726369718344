@import 'styles/resources.scss';

#error {
  border: 1px solid $red !important;
}

.tableTextArea {
  border: 1px solid $table-input-border;
  border-radius: 3px;
  background-color: transparent;
  color: $primary-color;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 8px;
  font-size: $table-body-font-size;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $table-input-border;
    border-radius: 10px;
  }

  &:disabled {
    border: none;
  }
}

.tableText,
.tableBorderedText {
  display: flex;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
}

.tableBorderedText {
  border: 1px solid $table-input-border;
  background-color: transparent;
  text-align: center;
  font-size: 14px;
  resize: none;

  &.disabled {
    border: none;
  }
}

.tableDropdown {
  width: 100%;
  min-height: 52px;
  border: 1px solid $table-input-border;
  padding: calc(0.875em - 1px) 0.75em;
  padding-right: 32px;
  height: auto;
  color: $secondary-color;
  text-align: left;

  &:disabled {
    border: none;
    background-image: none;
    cursor: default;
  }
}

.id {
  width: 100px;
}

.description {
  width: 525px;
  color: $primary-color;

  div[contenteditable='true'] {
    min-height: 119px;
    max-height: 119px;
  }

  &.error {
    div[contenteditable='true'] {
      @extend #error;
    }
  }
}

.category {
  width: 150px;
}

.subCategory {
  width: 136px;
}

.published {
  width: 150px;
  font-size: $table-body-font-size-small;
  color: $secondary-color;
}

.model {
  color: $secondary-color;
}

.newItem {
  border: 3px solid $tertiary-color !important;
}

.errItem {
  @extend #error;
}
