@import 'styles/resources.scss';

$sideMenuWidth: 200px;

.navContainer {
  padding-left: $sideMenuWidth;
  transition: padding-left $transition-timing;

  &.collapsed {
    padding-left: 80px;
  }
}

.mainContent {
  padding: 0 $padding-h $padding-h $padding-h;
}

.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  font-size: 0.9em;
  color: $white;
  width: $sideMenuWidth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $sidemenu-background;
  transition: width $transition-timing;
  overflow-y: auto;
  z-index: 5;

  &.collapsed {
    width: 80px;
  }
}

.title {
  font-size: 2em;
  padding-left: 20px;
}

.titleCollapsed {
  font-size: 2em;
  text-align: center;
}

.listContainer {
  ul {
    padding: 0;
    margin-bottom: 16px;

    a {
      position: relative;
      display: block;
      text-decoration: none;
      color: $sidemenu-link-text;
      padding: 8px 24px;
      margin-bottom: 8px;
      z-index: 2;

      &:global(.active) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: $sidemenu-link-hover-background;
          color: $sidemenu-link-hover-text;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          height: 100%;
          width: 95%;
          z-index: -1;
        }
      }
    }
    li {
      list-style-type: none;

      &.smallText {
        font-size: 11px;
      }

      ul li a {
        padding-left: 64px;
      }
    }
  }

  &.collapsed {
    ul a {
      display: flex;
      align-items: center;
      justify-content: center;

      &:global(.active) {
        width: 90%;
      }
    }
  }
}

.sideMenuIcon {
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  color: $white;
  cursor: pointer;
  font-size: 24px;
  outline: none;
}

.sideMenuChildren {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}

.collapseToggle {
  border-top: 1px solid $slate;
  text-align: right;
  padding: 12px;
  &.collapsed {
    text-align: center;
  }
}

.footerContainer {
  display: flex;
  padding: 12px;
  align-items: center;
  flex-direction: column;

  &.collapsed {
    justify-content: center;
  }
}

.footer {
  &.collapsed {
    justify-content: center;

    ul {
      margin-bottom: 0;

      a:last-child {
        margin-bottom: 0;
      }
    }
  }
}
