@import 'styles/resources';

.tableTabs {
  position: relative;
  z-index: 2;
  margin-top: 60px;
}

.tableTab {
  cursor: pointer;
  padding: 16px 49px 16px 24px;
  border: 1px solid $table-border;
  border-radius: 4px 4px 0 0;
  background: $lighter-slate;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  outline: 0;

  &.selected {
    background: $white;
    border-bottom: 1px solid $white;
  }
}