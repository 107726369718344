ul.columnList {
  display: block;
  list-style: none;
  padding: 0;
  margin-bottom: 16px;

  li {
    padding-left: 32px;
    margin-bottom: 16px;
  }
}
