@import 'styles/resources';

.headerCell {
  padding: 0 16px;
  vertical-align: middle;
  color: $primary-color;

  &.border {
    border-left: 1px solid $table-border;
    border-right: 1px solid $table-border;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
