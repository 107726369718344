@import 'styles/resources';

.stickyNav td {
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 2;
  font-weight: 500;
  padding-left: 25px;
}

.aapTable {
  tbody.appBody tr td {
    font-size: 12px;
    padding-left: 25px;
  }
}

.checkBox {
  padding-right: 25px;
}
