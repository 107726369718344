@import 'styles/resources';

@mixin addColTypeWidth($width) {
  width: $width;
  min-width: $width !important;
}
.colType {
  &-applyAccept {
    @include addColTypeWidth($vd-table-acceptApply);
  }
  &-applicability {
    @include addColTypeWidth($vd-table-applicability);
  }
  &-bpName {
    @include addColTypeWidth($bp-table-name);
  }
  &-bpCategory {
    @include addColTypeWidth($bp-table-category);
  }
  &-changes {
    @include addColTypeWidth($vd-table-changes);
  }
  &-description {
    @include addColTypeWidth($vd-table-description);
  }
  &-dropdown {
    @include addColTypeWidth($vd-table-dropdown);
  }
  &-flags {
    @include addColTypeWidth($vd-table-flags);
  }
}

.tableCell {
  &.large {
    padding: 16px 8px;
    height: 144px;
    vertical-align: middle;
  }
  &.center {
    text-align: center;
  }

  &.border {
    border-left: 1px solid $table-border;
    border-right: 1px solid $table-border;
  }
  &.redStrike {
    text-decoration: line-through;
    color: $red;

    span {
      color: $black;
    }
  }
  &.smallText {
    @include fontSize(12px);
  }
  &.whiteBg {
    background-color: $white;
  }
}
