@import 'styles/resources';

.msrpSection {
  display: flex;
  flex-direction: column;
  width: 230px;
}

.msrpLabel {
  color: $green;
  cursor: pointer;
}
  
.header {
  font-weight: bold;
  margin-bottom: 24px;
  color: $primary-color;
  font-size: 15px;
  margin-top: 10px;
}

.currencyInput {
  position: relative;
}

.currencyInput input {
  padding-right: 18px;
  width: 100%;
  margin-top: 5px;
  padding-left: 12px;
}

.currencyInput:after {
  position: relative;
  content: "$";
  left: 3px;
  bottom: 23px;
  font-size: 15px;
}
  
.label {
  font-size: 12px;
  font-weight: bold;
}
  
.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}