@import 'styles/resources';

.vdTableCell {
  min-width: 118px;
  font-size: 12px;

  &.large {
    min-width: 200px;
  }

  &.maxWidth {
    max-width: 350px;
  }
}

.container {
  padding: 0 10px;

  &.scrollable {
    height: $table-row-height;
    overflow: auto;
  }
}
