.dropdownList {
  min-width: 78px !important;

  button {
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }

  li {
    justify-content: center !important;
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }
}
