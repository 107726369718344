@import 'styles/resources';

.row {
  margin-bottom: 16px;

  .fillerCell {
    height: 0;
    padding: 0;
  }
}

.dragCell {
  background-color: $white;
}

.spannedCell {
  background: $white;
}

.maxWidthSmall {
  max-width: 300px;
}
