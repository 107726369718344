@import "styles/resources";

.root {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  .flagIcon {
    color: $slate;
    &.active {
      color: $buttercup;
    }
  }
}
