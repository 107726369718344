@import 'styles/resources.scss';

.modalContainer {
  width: 689px !important;
}

.modalBody {
  padding: 0 !important;
}

.bodyDescription {
  padding: 24px;
  font-size: 16px;
}

.secondaryHeader {
  padding-left: 69px;

  button {
    font-size: 10px !important;
    margin: 0 10px !important;
  }
}

.disabledButton {
  color: $gray !important;
}

.modalFooter {
  box-shadow: 0 0 6px 0 $gray;
}
