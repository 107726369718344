.seriesColumn {
  position: static !important;
  font-weight: 500;
  padding-bottom: 0;
  padding-right: 109px;
  padding-left: 36px;
  border-bottom: none !important;
}

.seriesColumnES {
  position: static !important;
  font-weight: 500;
  padding-bottom: 0;
  padding-right: 79px;
  border-bottom: none !important;
}
.seriesColumnSeriesId {
  position: static !important;
  font-weight: 500;
  padding-bottom: 0;
  border-bottom: none !important;
}
