@import 'styles/resources.scss';

.row {
  display: flex;
  height: 40px;
  .year,
  .series,
  .empty {
    display: inline-flex;
    width: 50%;
    height: inherit;
    align-items: center;
    font-size: 13px;
    color: $slate;
  }
  .year,
  .empty {
    padding-left: 20px;
  }
}
