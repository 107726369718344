@import 'styles/resources';

.checkbox {
  top: 14px;
  left: 0px;
  width: 20px;
  height: 20px;
  label {
    &::before {
      border-radius: 14px;
    }
  }
}

.header {
  padding-left: 22px;
  font-weight: 500;
  font-size: 16px;
}

.dphHeader {
  padding-left: 140px;
}

.dph {
  padding-left: 54%;
}
