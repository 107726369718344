@import 'styles/resources';

.checkbox {
  margin-left: 6px;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;

    &::before {
      position: initial;
      width: 13px;
      height: 13px;
    }

    &::after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
    }
  }

  .checkboxLabel {
    @include fontSize(10px);
    font-family: $font-family-roboto;
    font-weight: bold;
    color: $slate;
    padding-left: 4px;
  }
}

.nameInput,
.descriptionInput {
  width: 170px;
  padding: 3px 5px;

  div[contenteditable='true'] {
    border: 1px solid $input-border;
    border-radius: 3px;
    padding: 3px;
  }

  &.disabled {
    div[contenteditable='true'] {
      border: none;
    }
  }
}

.nameInput {
  margin-top: 3px;
  div[contenteditable='true'] {
    max-height: 27px;
    min-height: 27px;
  }
}

.descriptionInput {
  div[contenteditable='true'] {
    max-height: 58px;
    min-height: 58px;
  }
}
