.descriptionText {
  max-width: 250px;
  margin: 0;
  word-break: break-word;

  &.small {
    max-width: 150px;
    min-width: 150px;
  }
  &.large {
    max-width: 400px;
    min-width: 400px;
  }
}
