.bold {
  font-weight: bold;
}

.footer {
  justify-content: space-between;
}

.ctaContainer {
  > button {
    margin-right: 16px;
  }
}