@import 'styles/resources';

$border: 1px solid $mystic;

.modelCodeCell {
  border-top: $border;
  font-weight: normal;
  @include fontSize(12px);
  padding: 5px;
  font-family: $font-family-roboto;
}

.modelHeader {
  text-align: center;
  height: 80px;
  @include fontSize(11px);
  color: $mine-shaft;
  padding: 1px;
  border: 1px solid $table-border;
  border-bottom: 1px solid $table-header-bottom-border;

  .grade {
    font-weight: bold;
    font-family: $font-family-roboto;
    @include fontSize(11px);
    width: 160px !important;
  }

  .title {
    font-weight: normal;
    font-family: $font-family-roboto;
    @include fontSize(11px);
    width: 160px !important;
  }
}

.horizontalScrollTable {
  display: flex;
  flex-direction: column;
}

.headerSection {
  position: -webkit-sticky;
  position: sticky;
  top: 53px;
  z-index: 1;
}

.scrollerDiv {
  overflow: auto;

  &Hidden {
    overflow: auto;
  }
}

.headerRow {
  display: flex;
  width: 100%;
}
