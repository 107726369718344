@import 'styles/resources';

.checkbox {
  label {
    display: flex;
    margin: 0px 5px 0px 0px;
    outline: none;
    padding-left: 10px;
    padding-top: 8px;
    white-space: pre-wrap;
    line-height: 97%;

    &::before {
      top: 5px;
      left: 165px;
      width: 24px;
      height: 24px;
      border-radius: 14px;
    }
    &::after {
      left: 175px;
      top: 12px;
    }
  }
  input:checked + label::after {
    border-color: $white;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
  }

  input:checked + label::before {
    background-color: $tertiary-color;
    border: 1px solid $tertiary-color;
  }
}

.editSeriesinput {
  display: flex;
  justify-content: left;
  margin-top: 5px;
  margin-left: 5px;
  width: 125px;
  font-size: 12px;
}

.editButton {
  display: inline-flex;
  justify-content: left;
  align-items: center;
  padding: calc(0.375em - 1px) 0.75em;
  height: 3em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 10px;
  font-weight: bold;
  width: min-content;
  border: none;
  line-height: 1.5;
  background-color: transparent;
  color: $secondary-color;
  cursor: pointer;
  outline: 0;
  margin: 0 17px 0 0;

  span {
    font-size: 15px;
  }

  svg {
    &:hover {
      color: $blue;
    }
  }
}

.saveEdit {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-left: 20px;
  height: 3px;
  button {
    width: 40px;
  }
}
