@import 'styles/resources';

.multiselectcheckbox {
  cursor: pointer;

  input {
    -webkit-appearance: none;
    outline: none;
  }

  // default skin
  &.default {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    line-height: 11px;
    color: $slate;

    input {
      margin-right: 4px;
      position: relative;
      flex-shrink: 0;
      width: 17px;
      height: 17px;
      border: 1px solid $slate;
      background: $white;
      margin-top: 0px;
      border-radius: 55%;

      &::before {
        content: ' ';
        position: absolute;
        top: 75%;
        right: 75%;
        bottom: 75%;
        left: 75%;
        transition: all 0.1s;
        background: $white;
      }

      &:checked {
        background: $tertiary-color;
        border: none;
        &::before {
          top: 6px;
          right: 6px;
          bottom: 6px;
          left: 6px;
          border-radius: 55%;
        }
      }
    }
  }
}
