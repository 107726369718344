@import 'styles/resources';

.descriptionParentName {
  color: $slate;
}

.modelApplicabilityInput {
  div[contenteditor='true'] {
    width: 138px;
  }
}
