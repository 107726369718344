@import 'styles/resources';

$cell-width: 120px;
$cell-height: 28px;
$spacer-width: 10px;
$border-style: 1px solid $lighter-gray;
$font-size: 10px;

.row {
  min-height: $cell-height;
  height: $cell-height;
  background-color: $white;

  &:nth-child(even) {
    background-color: $lighter-gray;
  }

  .cell {
    position: relative;
    min-width: $cell-width;
    width: $cell-width;
    min-height: $cell-height;
    height: $cell-height;
    border: $border-style;
    font-size: $font-size;
    text-align: center;
    padding: 5px;
  }

  .align {
    &Left {
      text-align: left;
    }
  }

  .headliner {
    text-align: left;
  }

  .spacerCell {
    width: $spacer-width;
    min-width: $spacer-width;
  }
}

.groupCell {
  background-color: $light-blue;
  cursor: pointer;

  input {
    width: 100%;
    height: 100%;
    padding: 0;
    background: none;
    border: none;
    color: $blue;
    font-weight: bold;
    outline: none;
    text-align: center;

    &::placeholder {
      color: $blue;
      opacity: 50%;
    }
  }
}

.rejectNotes {
  position: absolute;
  top: 0;
  left: 0;
}
